import React, { Component } from "react";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget } from "react-icons/fi";
import {  GiBackwardTime  } from "react-icons/gi";
import {  FaCoins  } from "react-icons/fa";



const ServiceList = [
    {
        id:'telesales-upsell-and-cross-sell',
        icon: <FiPieChart />,
        title: 'Telesales - Upsell & Cross Sell',
        description: 'Sales is an outcome, not a goal.'
    },
    {
        id:'lead-generation',
        icon: <FiTrendingUp />,
        title: 'Lead Generation',
        description: 'We do a lot of one-night stands in lead generation and not enough long-term relationships.'
    },
    {
        id:'channel-enablement',
        icon: <FiTarget />,
        title: 'Channel Enablement',
        description: 'Don’t depend on a single channel.'
    },
    {
        id:'customer-satisfaction-surveys',
        icon: <FiRefreshCw />,
        title: 'Customer Satisfaction Surveys',
        description: 'If you don’t appreciate your customers, someone else will.'
    },
    {
        id:'loyalty-and-retention',
        icon: <GiBackwardTime />,
        title: 'Loyalty and Retention',
        description: 'Satisfaction is a rating. Loyalty is a brand.'
    },
    {
        id:'telemarketing',
        icon: <FaCoins />,
        title: 'Telemarketing',
        description: 'The magic ‘mind reading’ phrase is: “If that was me, what would I want?”'
    }
]

class ServiceTwo extends Component{

    state = {
        show: false,
        
      }
    
        toggle = () => this.setState((currentState) => ({show: !currentState.show} ));


    render(){
        let title = 'Customer acquisition',
        description = 'As business owners, we must often ask ourselves what is our company’s lifetime and how can we expand it. Whilst there are many different answers, it all boils down to customer acquisition.  Whether you’re a new entrepreneur or a seasoned veteran, it never stops being relevant. If you want to grow your business consistently and profitably, you need to think of customer acquisition not as an outcome but as a process. '
        return(
            <React.Fragment>
                <div className="row">
                    
                    <div className="col-lg-8 col-12 mt_md--50">
                    {this.state.show &&    <div className="row service-one-wrapper ">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`/solutions/${val.id}`}>
                                        <div className="service service__style--2 ">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>}
                        {!this.state.show && <div onClick={this.toggle} className="thumbnail service service__style--1" > <img src={`/assets/images/outbound5.png`}/> </div>}

                    </div>

                    <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 style={{fontSize:'30px'}} className="title">{title}</h2>
                            <p style={{textAlign:'justify'}}>{description}</p>
                            <p onClick={this.toggle}> {this.state.show ? <img src={`/assets/images/icons/arrow-right.png`} /> :  <img src={`/assets/images/icons/arrow-left.png`} />}</p>
                            
                        </div>
                        
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
