import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock , FiUser , FiMenu, FiX } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "./blog/BlogContent";
import Scrollspy from 'react-scrollspy';

class BlogDetails extends Component{
         // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }
    constructor(props)  {
        super(props)
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })

    }
    openModal () {
        this.setState({isOpen: true})
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}


    render(){

        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }


        return(
            <React.Fragment>
                <PageHelmet pageTitle='Why are surveys important?' />
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','about', 'solutions','blog', 'contact']} currentClassName="is-current" offset={-200} > 
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#about">About</a></li>
                                    <li><a href="/#solutions">Solutions</a></li>
                                   
                                    <li><a href={`${process.env.PUBLIC_URL}/blog`}>Blog</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--50" data-black-overlay="1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Why are surveys important? <br /> </h2>
                                    
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />December 29, 2020</li>
                                        <li><FiUser />Ilinca Gheorghiu</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner"style={{textAlign:'justify'}}>
                                        <p>The market study or satisfaction survey is the method by which you can define your business strategy, with the help of a research or investigation on the degree of customer satisfaction in relation to the products, services offered or in order to anticipate their needs and desires.</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>In this way, you discover what makes you different from the competition or how to position yourself in the market in relation to the competition. Also, knowing your strengths and weaknesses you can make the right decisions for development, diversification or consolidation.</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>Today we will discuss the most common method of gathering this information, the #survey. It involves the collection of data in a structured format, through a #questionnaire, with verification keys, validation according to determined criteria, performed on a representative sample (volume, demographic criteria, consumption profile, including the probability to purchase a complementary product to the basic one, etc.)</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>The difference between the two is that the survey involves both the set of questions and the process of collecting and analyzing the answers, while the questionnaire includes only the set of questions.</p>
                                        <h3 className="title mt--40"style={{textAlign:'justify'}}>How is a survey conducted?</h3>
                                        <p className="mt--40"style={{textAlign:'justify'}}>The survey can be addressed through several channels: through a face-to-face interview, on the phone, in writing, through applications or online. Regardless of the reason and the way it is performed, there are a number of common elements. These characteristics have been studied in detail by methodologists, psychologists and statisticians. </p>
                                        <div className="thumbnail">
                                            <img style={{display:'block', marginLeft:'auto',marginRight:'auto',width:'80%'}} src="/assets/images/blog/steps.png" alt="BLog Images"/>
                                        </div>
                                        <h3 className="title mt--40">Why conduct a survey?</h3>
                                        <p className="mt--40">A survey can answer many questions about the services you provide. Participants can give you feedback on the experience you have in the customer position, the degree of satisfaction, the probability of promoting your services. Based on this data, you can make informed decisions to improve the customer experience, the quality of the product or service you offer.</p>
                                        <p className="mt--40">Futhermore, if you want to launch a certain product or set of services, a survey helps you estimate its adoption rate, success. Through the answers given by your target audience, you can find out what changes need to be made.</p>
                                        <h3 className="title mt--40">Where can a survey be conducted?</h3>
                                        <p className="mt--40">If you have decided to take a survey, Bright Communication can help you!</p>
                                        <p className="mt--40">If you want a "complete package", starting with the formulation of the questions in the questionnaire, continuing with the actual process of supporting the survey and the interpretation, analysis of the data obtained, we can assist you in this endeavor.</p>
                                        <blockquote className="rn-blog-quote">Keep Calm and Have a #BrightSurvey!</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails;