import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock , FiUser , FiMenu, FiX } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "./blog/BlogContent";
import Scrollspy from 'react-scrollspy';

class BlogDetails extends Component{
         // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }
    constructor(props)  {
        super(props)
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })

    }
    openModal () {
        this.setState({isOpen: true})
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}


    render(){

        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }


        return(
            <React.Fragment>
                <PageHelmet pageTitle='What is telephonophobia?' />
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','about', 'solutions','blog', 'contact']} currentClassName="is-current" offset={-200} > 
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#about">About</a></li>
                                    <li><a href="/#solutions">Solutions</a></li>
                                    
                                    <li><a href={`${process.env.PUBLIC_URL}/blog`}>Blog</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--49" data-black-overlay="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">What is telephonophobia? <br /> </h2>
                                    
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />December 29, 2020</li>
                                        <li><FiUser />Ilinca Gheorghiu</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner"style={{textAlign:'justify'}}>
                                        <p>We use our phone every day. It is our devoted companion, it gives us answers to all the questions we have and it reminds us of our loved ones' birthdays. For most, it is an extension of the hand, however some people view it as a constant source of stress. The reason is simple (and ironic!), its original function, the call.</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>The fear of talking on the phone is called telephonophobia and is a kind of manifestation of social anxiety. Like the phobia of public speaking, it comes from the fear of being mocked, misunderstood or not accepted. Those affected can easily have a face-to-face conversation. It is due to the fact that the implication of a conversation can be perceived much more easily through the gestures and facial expressions of the interlocutor.</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>A 2019 study found that 63% of UK office workers panic when they have to talk on the phone. Surprisingly, the most affected are young people.</p>
                                        <div className="thumbnail">
                                            <img style={{display:'block', marginLeft:'auto',marginRight:'auto',width:'80%'}} src="/assets/images/blog/pins.png" alt="BLog Images"/>
                                        </div>
                                        
                                        <p className="mt--40"style={{textAlign:'justify'}}>The manifestation varies in intensity, a telephonophob can have varies from a full-blown panic attack hearing his phone ring to just a minor inconvenience. Regardless of the depth of this fear, it can have a negative impact, especially on the career, so it is important to try to reduce it.</p>
                                        <h3 className="title mt--40"style={{textAlign:'justify'}}>How can we get rid of… telephonophobia? </h3>
                                        <p className="mt--40"style={{textAlign:'justify'}}>Although there is no standard procedure, there are certain ways we can reduce the fear of phone calls. Here are some <b>#brightcommunicationapproved</b> tips:</p>
                                        <ul className="list-style">
                                                    <li>structure your ideas that you want to convey before you call, you are less likely to get lost, and your interlocutor will understand more easily what you want to tell him;</li>
                                                    <li>control your breathing, regular breathing will calm you;</li>
                                                    <li>listen carefully: if you focus on what the other person is telling you you will eventually forget about fear;</li>
                                                    <li>smile, even if it is forced, the smile induces a state of euphoria, because it is associated at a subconscious level with positive feelings;</li>
                                                    <li>speaks often on the phone, repeated exposure to a stimulus decreases in intensity.</li>
                                        </ul>
                                        <p className="mt--40"style={{textAlign:'justify'}}>And if you ever feel overwhelmed by the fact that you have to make an important phone call, think that some people who suffer from this phobia work in the Call Center (such as me) and have managed to get over (somewhat) the fear of telephone conversations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails;