import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [

    {
            images: '02',
            title: 'Jeni Grigorescu',
            designation: 'General Director',
            socialNetwork: [
                
                {
                    icon: <FaLinkedinIn />,
                    url: 'https://www.linkedin.com/in/jeni-grigorescu-73487b3'
                },
            ]
        },
     {
            images: '03',
            title: 'Loredana Mihaila',
            designation: 'Financial Director',
            socialNetwork: [
                
                {
                    icon: <FaLinkedinIn />,
                    url: 'https://www.linkedin.com/in/loredana-mihaila-1bb633b7'
                },
                
            ]
        },
    {
            images: '01',
            title: 'Adrian Bunea',
            designation: 'Development Director',
            socialNetwork: [
                
                {
                    icon: <FaLinkedinIn />,
                    url: 'https://www.linkedin.com/in/adrian-bunea-080a8a1'
                },
                
            ]
        },
    
    ];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Blog Images"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;