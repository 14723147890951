import React, { Component } from "react";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget } from "react-icons/fi";

const ServiceList = [
    
]

class ServiceTwo extends Component{

    state = {
        show: false,
        
      }
    
        toggle = () => this.setState((currentState) => ({show: !currentState.show} ));


    render(){
        let title = 'Business Process Outsourcing',
        description = 'Do what you do best, outsource the rest. Business process outsourcing, or BPO, is a business practice in which another company is hired to perform one or multiple linked tasks that the hiring organization requires for its own business to operate successfully. Why outsource? It is the most efficient option of staying away from tasks that take up time and are not related to the true core of your company. First time outsourcing or looking for a change? Try... Bright!😊'
        return(
            <React.Fragment>
                <div className="row">
                <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 style={{fontSize:'30px'}} className="title">{title}</h2>
                            <p style={{textAlign:'justify'}}>{description}</p>
                           <p><a href="/solutions/bpo"><img src={`/assets/images/icons/arrow-right.png`} /></a></p>
                            
                        </div>
                        
                    </div>
                    
                    <div className="col-lg-8 col-12 mt_md--50">
                    <div className="thumbnail service service__style--1" > <a href="/solutions/bpo"><img src={`/assets/images/bpo.png`}/></a> </div>

                    </div>

                    

                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
