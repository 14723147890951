// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import HomePortfolio from './home/HomePortfolio';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import PortfolioLanding from './home/PortfolioLanding';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import HomeParticlesRo from './home/HomeParticlesRo';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';
import InteriorLanding from './home/Interior';
import CorporateBusiness from './home/CorporateBusiness';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";
import Solutions from "./elements/Solutions";


import BlogDetailsSurveys from "./elements/BlogDetailsSurveys";
import BlogDetailsTelephonophobia from "./elements/BlogDetailsTelephonophobia";
import BlogDetailsIVR from "./elements/BlogDetailsIVR";


import ServiceDetailsCustomer from "./elements/ServiceDetailsCustomer";
import ServiceDetailsHelpDesk from "./elements/ServiceDetailsHelpDesk";
import ServiceDetailsInboundSales from "./elements/ServiceDetailsInboundSales";
import ServiceDetailsCustomerService from "./elements/ServiceDetailsCustomerService";
import ServiceDetailsLead from "./elements/ServiceDetailsLead";
import ServiceDetailsSurveys from "./elements/ServiceDetailsSurveys";
import ServiceDetailsDataentry from "./elements/ServiceDetailsDataentry";
import ServiceDetailsDatacleaning from "./elements/ServiceDetailsDatacleaning";
import ServiceDetailsProfiling from "./elements/ServiceDetailsProfiling";
import ServiceDetailsAccountservice from "./elements/ServiceDetailsAccountservice";
import ServiceDetailsClaim from "./elements/ServiceDetailsClaim";
import ServiceDetailsRetention from "./elements/ServiceDetailsRetention";
import ServiceDetailsStaff from "./elements/ServiceDetailsStaff";
import ServiceDetailsBpi from "./elements/ServiceDetailsBpi";
import ServiceDetailsTelemarketing from "./elements/ServiceDetailsTelemarketing";
import ServiceDetailsTelesales from "./elements/ServiceDetailsTelesales";
import ServiceDetailsUpsell from "./elements/ServiceDetailsUpsell";
import ServiceDetailsTechnical from "./elements/ServiceDetailsTechnical";
import ServiceDetailsOnline from "./elements/ServiceDetailsOnline";
import ServiceDetailsTehnicalsupport from "./elements/ServiceDetailsTehnicalsupport";
import ServiceDetailsOrdermanagement from "./elements/ServiceDetailsOrdermanagement";
import ServiceDetailsBilling from "./elements/ServiceDetailsBilling";
import ServiceDetailsScheduling from "./elements/ServiceDetailsScheduling";
import ServiceDetailsCompliants from "./elements/ServiceDetailsComplaints";
import ServiceDetailsLivechat from "./elements/ServiceDetailsLivechat";
import ServiceDetailsEmailsupport from "./elements/ServiceDetailsEmailsupport";
import ServiceDetailsEmailmarketing from "./elements/ServiceDetailsEmailmarketing";
import ServiceDetailsSocialmedia from "./elements/ServiceDetailsSocialmedia";
import ServiceDetailsAi from "./elements/ServiceDetailsAi";
import ServiceDetailsAudit from "./elements/ServiceDetailsAudit";
import ServiceDetailsConsultancy from "./elements/ServiceDetailsConsultancy";
import ServiceDetailsRecruit from "./elements/ServiceDetailsRecruit";
import ServiceDetailsBusinessinteg from "./elements/ServiceDetailsBusinessinteg";
import ServiceDetailsBPO from "./elements/ServiceDetailsBPO";
import ServiceDetailsChannel from "./elements/ServiceDetailsChannel";
import TermsDetails from "./elements/TermsDetails";

//RO

import SolutionsRo from "./elements/SolutionsRo";



// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeParticles}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/ro`} component={HomeParticlesRo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} component={InteriorLanding}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness}/>


                    {/* Element Layot */}
                    <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog/why-are-surveys-important`} component={BlogDetailsSurveys}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog/what-is-telephonophobia`} component={BlogDetailsTelephonophobia}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog/ivr`} component={BlogDetailsIVR}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/solutions`} component={Solutions}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/ro/solutii`} component={SolutionsRo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions-details`} component={ServiceDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/customer-care`} component={ServiceDetailsCustomer}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/outbound-campaigns`} component={ServiceDetailsCustomerService}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/help-desk`} component={ServiceDetailsHelpDesk}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/inbound-sales`} component={ServiceDetailsInboundSales}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/lead-generation`} component={ServiceDetailsLead}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/customer-satisfaction-surveys`} component={ServiceDetailsSurveys} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/data-entry`} component={ServiceDetailsDataentry} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/data-cleaning-collection`} component={ServiceDetailsDatacleaning} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/customer-profiling`} component={ServiceDetailsProfiling} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/account-service-product-activation`} component={ServiceDetailsAccountservice} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/claim-management`} component={ServiceDetailsClaim} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/loyalty-and-retention`} component={ServiceDetailsRetention} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/staff-leasing`} component={ServiceDetailsStaff} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/business-process`} component={ServiceDetailsBpi} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/telemarketing`} component={ServiceDetailsTelemarketing} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/telesales`} component={ServiceDetailsTelesales} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/telesales-upsell-and-cross-sell`} component={ServiceDetailsUpsell} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/tehnical-support`} component={ServiceDetailsTechnical} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/online-support`} component={ServiceDetailsOnline} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/tehnical-support-help-desk`} component={ServiceDetailsTehnicalsupport} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/order-management`} component={ServiceDetailsOrdermanagement} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/billing-finances`} component={ServiceDetailsBilling} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/scheduling-reservations`} component={ServiceDetailsScheduling} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/complaints-management`} component={ServiceDetailsCompliants} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/live-chat`} component={ServiceDetailsLivechat} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/email-support`} component={ServiceDetailsEmailsupport} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/email-marketing`} component={ServiceDetailsEmailmarketing} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/social-media-communication`} component={ServiceDetailsSocialmedia} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/ai-solutions`} component={ServiceDetailsAi} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/audit-and-business-consulting`} component={ServiceDetailsAudit} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/business-consulting`} component={ServiceDetailsConsultancy} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/recruitment-process-head-hunting`} component={ServiceDetailsRecruit} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/business-integration`} component={ServiceDetailsBusinessinteg} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/bpo`} component={ServiceDetailsBPO} />
                    <Route exact path={`${process.env.PUBLIC_URL}/solutions/channel-enablement`} component={ServiceDetailsChannel} />
                    <Route exact path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={TermsDetails} />

                    {/* Blocks Elements  */}
                    <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>


                    
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();