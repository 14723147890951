import React, { Component } from "react";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget } from "react-icons/fi";

const ServiceList = [
    {
        id:'audit-and-business-consulting',
        icon: <FiCast />,
        title: 'Audit',
        description: 'Done is better than perfect.'
    },
    {
        id:'business-consulting',
        icon: <FiCast />,
        title: 'Business Consulting',
        description: 'What’s measured improves.'
    },
    {
        id:'staff-leasing',
        icon: <FiLayers />,
        title: 'Staff Leasing',
        description: 'To win in the marketplace, you must first win in the workplace.'
    },
    {
        id:'ai-solutions',
        icon: <FiUsers />,
        title: 'AI Solutions',
        description: 'Our intelligence is what makes us bright, and AI is an extension of that.'
    }
]

class ServiceTwo extends Component{

    state = {
        show: false,
        
      }
    
        toggle = () => this.setState((currentState) => ({show: !currentState.show} ));


    render(){
        let title = 'Business Integration',
        description = 'You didn’t come this far, just to get this far. Want to get even further? You need a strong business integration strategy. In order to do so, you can imagine it as multiple pieces of the puzzle, coming together in a complete, fully rounded solution. By synchronizing all facets of your business process, from front office, to back office, even the tools and applications - enables the optimization of key business processes that connect an organization with its trading partners: customers, suppliers, vendors, etc.'
        return(
            <React.Fragment>
                <div className="row">
                    
                    <div className="col-lg-8 col-12 mt_md--50">
                    {this.state.show &&    <div className="row service-one-wrapper ">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`/solutions/${val.id}`}>
                                        <div className="service service__style--2 ">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>}
                        {!this.state.show && <div onClick={this.toggle} className="thumbnail service service__style--1" > <img src={`/assets/images/bi3.png`}/> </div>}

                    </div>

                    <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 style={{fontSize:'30px'}} className="title">{title}</h2>
                            <p style={{textAlign:'justify'}}>{description}</p>
                            <p onClick={this.toggle}> {this.state.show ? <img src={`/assets/images/icons/arrow-right.png`} /> :  <img src={`/assets/images/icons/arrow-left.png`} />}</p>
                            
                        </div>
                        
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
