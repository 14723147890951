import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import { slideSlick } from "../page-demo/script";
import { aboutSlick } from "../page-demo/script";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import Footer from "../component/footer/Footer";
import ContactTwo from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";


import { Link, animateScroll as scroll } from "react-scroll";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Misiune',
        description: 'For us, Contact Center services are a form of art.  And, when it comes to art: creativity, rigurous technique, and playfulness are required in order to deliever a masterpiece.',
        
    },
    {
        textPosition: 'text-center',
        category: '',
        title: 'Viziune',
        description: 'We aspire to be the partener of choice that your company has. How will we achieve it: by combining proven expertise and amazing creativity of our team with the outstanding technology we have in place.',
        
    },
    {
        textPosition: 'text-center',
        category: '',
        title: 'Valori',
        description: 'In our dictionary, "impossible" has been replaced with "let’s try a different approach". We value thinking outside the box and efficiency in supporting our clients’ business evolve to the next level.',
        
    },
]

const AboutList = [
    {
        images:"3",
    },
    {
        images:"1",
    },
    {
        images:"4",
    },
    {
        images:"5",
    },



]



class HomeParticles extends React.Component{

        // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 500)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }




    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="Home" />

                {/* Start Header Area  */}
                <div className="App" >
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','about', 'solutions','team','testimonial','blog', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li style={{cursor: 'pointer' }} ><Link to="home"  smooth={true} duration={500} offset={-150} >Acasă</Link></li>
                                    <li style={{cursor: 'pointer' }} ><Link to="about" smooth={true} duration={500} offset={-10}>Despre noi</Link></li>
                                    <li style={{cursor: 'pointer' }} ><Link to="solutions" smooth={true} duration={500}>Soluții</Link></li>
                                    <li style={{cursor: 'pointer' }} ><Link to="team" smooth={true} duration={500}>Echipa</Link></li>
                                    <li style={{cursor: 'pointer' }} ><Link to="testimonial" smooth={true} duration={500} offset={-50}>Testimoniale</Link></li>
                                    <li style={{cursor: 'pointer' }} ><Link to="blog" smooth={true} duration={500}>Blog</Link></li>
                                    <li style={{cursor: 'pointer' }} ><Link to="contact" smooth={true} duration={500}>Contact</Link></li>   
                                </Scrollspy>
                                
                            </nav>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                </div>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div  style={{height:'100vh'}}>
                <div className=" slider-activation slider-creative-agency rn-page-title-area with-particles2 " id="home" >
                <video style={{ objectFit:'cover', position: 'absolute', width:'100vw', height:'100vh'}} className="video-mobile video-desktop"  autoPlay loop muted>
                <source src={`/assets/images/video.mov`} type="video/mp4" />
                </video>
                 <div className="breadcrumb-inner2">
                    <Slider  className="" {...slideSlick}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="inner">
                                    {value.category ? <span>{value.category}</span> : ''}
                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                </div>     
                            </div>
                        ))}
                    </Slider>     
                </div>
                </div>
                </div>
                {/* End Slider Area   */}

                

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5 " id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <Slider {...aboutSlick}>
                                    {AboutList.map((value , i ) => (
                                            <div className="thumbnail"  key={i}>
                                              <img src={`/assets/images/about/about-${value.images}.jpg`} alt="About Images"/> 
                                            </div>
                                    ))}
                                    </Slider>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Despre noi</h2>
                                            <p style={{ textAlign: "justify" }}> More than a simple vendor, we are a business partner. Bringing value to our clients with proven knowledge of the best industry practices and standards, high-quality service, thus we enable the enhancement of productivity whilst minimising the risks. <span style={{color:'red'}}>Our expertise combines the innovative approach of our young people</span></p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Cine suntem?</h3>
                                                    <p style={{ textAlign: "justify"}} >Team of experienced professional that share what keeps them up at night: business excellence, innovation and meaningful communication. Here is where technology meets creativity, which can be hard to balance in our results-driven world.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Ce facem?</h3>
                                                    <p style={{ textAlign: "justify"}}>Whatever situation may be encountered, from the very moment we discover it, we are working towards the solution. In only three words:  </p>
                                                    <p style={{ textAlign: 'justify'}}> <b> We. Solve. Everything.</b></p>
                                                    <p style={{ textAlign: 'justify'}}>(...well... almost 😊)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-title">
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <p style={{ textAlign: "justify" }}>Bright Communication will be here for you, 24/7, 365 days/year (sometimes 366).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


                {/* Start Service Area  */}
                <div  className="service-area creative-service-wrapper ptb--120 bg_color--1" id="solutions">
                    <div className="container">
                        
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceTwo  item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 



                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div  className="rn-team-area ptb--120 bg_color--1" id="team">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">Echipa noastră</h2>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 pb--120 pt--60" id="testimonial">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">Testimoniale</h2>
                                    <br></br>
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <Testimonial />
                        </div>
                    </div>
                </div>
                {/* End Testimonial Area */}
                

                {/* Start Blog Area */}
                <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="section-title text-left ">
                                    <h2>Ultimele noutați</h2>
                                    <p><a href="/blog">Citește toate articolele</a></p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className=" row mt--55 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0 ">
                            <div className="col-lg-12">
                                <Slider {...slickDot}>
                                    {PostList.map((value , i ) => (
                                        <div className="blog blog-style--1" key={i}>
                                            <div className="thumbnail">
                                            <a href={`/blog/${value.link}`}>
                                                    <img src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <p className="blogtype">{value.category}</p>
                                                <h4 className="title"><a href={`/blog/${value.link}`}>{value.title}</a></h4>
                                                <div className="blog-btn">
                                                    <a className="rn-btn text-white" href={`/blog/${value.link}`}>Citește mai mult</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>    
                    </div>    
                </div>
                {/* End Blog Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <ContactTwo />
                </div>
                {/* End Contact Us */}



                 {/* Start Footer Style  */}
                 <Footer />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;