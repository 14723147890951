import React, { Component } from "react";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget } from "react-icons/fi";

const ServiceList = [
    {
        id:'data-entry',
        icon: <FiCast />,
        title: '[Ro]Data Entry',
        description: 'Get it done fast, cost effective and with professional results.'
    },
    {
        id:'data-cleaning-collection',
        icon: <FiLayers />,
        title: '[Ro]Data cleaning & collection',
        description: 'What is measured it improves.'
    },
    {
        id:'customer-profiling',
        icon: <FiUsers />,
        title: '[Ro]Customer Profiling',
        description: 'The customer’s perception is your reality.'
    },
    {
        id:'account-service-product-activation',
        icon: <FiLayers />,
        title: '[Ro]Account, service & product activation',
        description: 'Purpose provides activation energy for living.'
    },
]

class ServiceTwo extends Component{

    state = {
        show: false,
        
      }
    
        toggle = () => this.setState((currentState) => ({show: !currentState.show} ));


    render(){
        let title = '[Ro]Back Office',
        description = '[Ro]Sometimes, it’s not only about the bigger picture. In order to scale, your company must complete dozens of small, back-office support tasks. Routine projects like data entry and customer profiling may not always be glamorous, but it’s vital that they don’t get pushed to the backburner. We will provide  high-quality back-office support, so your employees can focus on the specialized work that you hired them to do, rather than being stuck on performing menial tasks.';
        return(
            <React.Fragment>
                <div className="row">
                    <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 style={{fontSize:'30px'}} className="title">{title}</h2>
                            <p style={{textAlign: 'justify'}}>{description}</p>
                            <p onClick={this.toggle}> {this.state.show ? <img src={`/assets/images/icons/arrow-left.png`} /> :  <img src={`/assets/images/icons/arrow-right.png`} />}</p>
                            
                        </div>
                        
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                    {this.state.show &&    <div className="row service-one-wrapper ">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`/solutions/${val.id}`}>
                                        <div className="service service__style--2 ">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>}
                        {!this.state.show && <div onClick={this.toggle} className="thumbnail service service__style--1" > <img src={`/assets/images/backoffice4.png`}/> </div>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
