import React, { Component } from "react";
import * as emailjs from 'emailjs-com';
import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const txtFieldState = {
    value: "",
    valid: true,
    typeMismatch: false,
    errMsg: "" //this is where our error message gets across
};

class ContactTwo extends Component{
    
        state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        }


    handleSubmit(e) {
        e.preventDefault()
        alert('Email sent successfully')

        const { rnName, rnEmail, rnSubject, rnMessage } = this.state

        let templateParams = {
            from_name: rnEmail,
            to_name:  rnName,
            subject: rnSubject,
            message_html: rnMessage,
           }
           emailjs.send(
            'service_1i36w28',
            'template_dvtj3mg',
             templateParams,
            'user_xLqtgR3Tywf0on7cKTCxm'
           )
           this.resetForm()
    }

    resetForm() {
        this.setState({
          rnName: '',
          rnEmail: '',
          rnSubject: '',
          rnMessage: '',
        })
      }

      handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
      }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us.</h2>
                                <p style={{fontSize:'16px'}} className="description">The best way to find yourself is to lose yourself in the service of others.</p>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.handleSubmit.bind(this)}>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject *"
                                            required
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message *"
                                            required
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11395.89407893334!2d26.1274815!3d44.4337054!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x25843bfc539186a6!2sBright%20Communication%20SRL!5e0!3m2!1sro!2sro!4v1605187158620!5m2!1sro!2sro"
              width="645"
              height="600"
              frameBorder="0"
              style={{ border: 0, width: '100%' }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;