import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div style={{paddingLeft:'0'}} className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p style={{fontSize:'25px', textAlign:'justify'}} >I’ve been working with Bright Communication since the beginning of their activity and I can see a clear improvement in our business flow. Our major challenge was brand awareness: even though we are one of the biggest players in the office design market in Romania, we did not reach the visibility that Kinnarps has on a global level. Through perseverance and a very dedicated team, day by day we are getting closer to this goal. Moreover, I am confident that any time I need support, I can rely on this partnership.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Kinnarps Romania </span> - Managing Director, Dan Florescu</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p style={{fontSize:'25px', textAlign:'justify'}}>There's a feeling, as a conductor, I am familiar with: that you are not alone, that there's always someone unseen supporting you. In the concert hall it's the public; in day-to-day life, the people from Bright Communication. They were always there for me.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Daniel Jinga </span> - CEO Metropolitan Symphony</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            

                           
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                
                                
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;