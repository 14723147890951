import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Footer from "../component/footer/Footer";
import Scrollspy from 'react-scrollspy';
import BlogContent from "../elements/blog/BlogContent";
import ServiceInbound from "../elements/service/ServiceInbound";
import ServiceCustomer from "../elements/service/ServiceCustomer";
import ServiceBPO from "../elements/service/ServiceBPO";
import ServiceOther from "../elements/service/ServiceOther";
import ServiceBackoffice from "../elements/service/ServiceBackoffice";
import ServiceOnline from "../elements/service/ServiceOnline";
import ServiceBI from "../elements/service/ServiceBI";
import { Link, animateScroll as scroll } from "react-scroll";


const ServiceListInbound = [
    {
        id:'customer-care',
        icon: <FiUsers />,
        title: 'Customer Care',
        description: 'Because we know that you care about your customers, Bright Communication has special designated teams to respond to their needs in a timely manner, while making sure they have the brightest customer experience possible. '
    }
]

const ServiceListOutbound = [
    {
        id:'telemarketing',
        icon: <FiPieChart />,
        title: 'Telemarketing',
        description: 'We can help you in the data entry process, so you can focus on the important tasks. '
    },
    {
        id:'telesales',
        icon: <FiTrendingUp />,
        title: 'Telesales',
        description: 'We can help you in the data entry process, so you can focus on the important tasks.'
    },
    {
        id:'lead-generation',
        icon: <FiTarget />,
        title: 'Lead Generation',
        description: 'We can help you in the data entry process, so you can focus on the important tasks.'
    },
    {
        id:'upsell-cross-sell',
        icon: <FiRefreshCw />,
        title: 'UpSell & Cross Sell',
        description: 'We can help you in the data entry process, so you can focus on the important tasks.'
    },
    {
        id:'outbound-campaigns',
        icon: <FiPhoneOutgoing />,
        title: 'Outbound campaigns',
        description: 'We can help you in the data entry process, so you can focus on the important tasks.'
    }
]

const ServiceListBackoffice = [
    {
        id:'tehnical-support',
        icon: <FiSettings />,
        title: 'Tehnical Support',
        description: 'We can help you in the data entry process, so you can focus on the important tasks. We can help you in the data entry process, so you can focus on the important tasks.'
    }
]

const ServiceListBPO = [
    {
        id:'business-process',
        icon: <FiZap />,
        title: 'Business Process Integration',
        description: 'BPI allows for automation of business processes, integration of systems and services, and the secure sharing of data across numerous applications. '
    }
]

const ServiceListOtherservices = [
    {
        id:'online-support',
        icon: <FiCast />,
        title: 'Online Support',
        description: 'A business needs to attract new customers and retain the ones already acquired, so it can stabilize and increase its revenue.'
    },
    {
        id:'staff-leasing',
        icon: <FiLayers />,
        title: 'Staff leasing',
        description: 'Personnel leasing or staff loan is a complex service consisting of the supply of talent, upon request, to cover the need for personnel.'
    },
    {
        id:'backoffice',
        icon: <FiUsers />,
        title: 'Back office',
        description: 'BPI allows for automation of business processes, integration of systems and services, and the secure sharing of data across numerous applications. '
    },
    {
        id:'data-cleaning-collection',
        icon: <FiLayers />,
        title: 'Data cleaning / Data collection',
        description: 'Our Data Collection Specialists performs a variety of data entry, verification and related duties for processing orders.'
    },
]


class Service extends React.Component{

         // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 500)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }



    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    
    
    render(){

        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return(
            <React.Fragment>
                <PageHelmet pageTitle='Solutions' />
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','about', 'solutions','blog', 'contact']} currentClassName="is-current" offset={-200} > 
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#about">About</a></li>
                                    <li><a href="/#solutions">Solutions</a></li>
                                    
                                    <li><a href={`${process.env.PUBLIC_URL}/blog`}>Blog</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/contact`}>Contact</a></li>
                                </Scrollspy>
                            </nav>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Start Breadcrump Area */}
                <div  style={{height:'100vh'}}>
                <div className="rn-page-title-area slider-creative-agency with-particles2 " >
                <video style={{ objectFit:'cover' ,position: 'absolute', width:'100vw', height:'100vh'}} className="video-mobile video-desktop"  autoPlay loop muted playsinline>
                <source src={`/assets/images/solutions.mp4`} type="video/mp4" />
                </video>
                
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div  className="breadcrumb-inner">
                                <h2 className="title theme-gradient">Solutions</h2>
                                    
                                    <h2 className="title ">
                                    <ul style={{paddingTop: '10%'}} className="page-list">
                                    <Scrollspy items={['inbound-services']}>
                                    <Link to="inbound-services" smooth={true} duration={500} offset={-70} ><li style={{paddingLeft: "0px" , paddingRight: "0px", cursor: 'pointer' }} className="mouse"></li></Link>
                                    </Scrollspy>
                                    </ul>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                {/* End Breadcrump Area */}
                {/* Start Service Area  */}
                <div  className="service-area creative-service-wrapper ptb--120 bg_color--1" id="inbound-services">
                    <div className="container">
                        
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceInbound  item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 


                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper pt--90 pb--120  bg_image bg_image--3" id="customer-acquisition">
                    <div className="container">
                        
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceCustomer  item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="back-office">
                    <div className="container">
                        
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceBackoffice  item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper pt--90 pb--120  bg_image bg_image--3" id="online-support">
                    <div className="container">
                        
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceOnline  item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 


                {/* Start Service Area  */}
                <div  className="service-area creative-service-wrapper ptb--120 bg_color--1" id="bpo">
                    <div className="container">
                        
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceBPO  item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 


                {/* Start Service Area  */}
             <div  className="service-area creative-service-wrapper pt--90 pb--120  bg_image bg_image--3" id="bi">
                    <div className="container">
                        
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceBI  item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;