import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Bright Communication - Always on the Bright Side </title>
                    <meta name="description" content="test" />
                    <meta name="keywords"    content="test" />
                    <meta property="og:image" content="http://beta.brightcommunication.ro/assets/images/logo/logo-all-dark.png" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
