import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock , FiUser , FiMenu, FiX } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "./blog/BlogContent";
import Scrollspy from 'react-scrollspy';

class BlogDetails extends Component{
         // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }
    constructor(props)  {
        super(props)
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })

    }
    openModal () {
        this.setState({isOpen: true})
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}


    render(){

        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }


        return(
            <React.Fragment>
                <PageHelmet pageTitle='Interactive Voice Response' />
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','about', 'solutions','blog', 'contact']} currentClassName="is-current" offset={-200} > 
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#about">About</a></li>
                                    <li><a href="/#solutions">Solutions</a></li>
                                  
                                    <li><a href={`${process.env.PUBLIC_URL}/blog`}>Blog</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--51" data-black-overlay="1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Interactive Voice Response <br /> </h2>
                                    
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />December 29, 2020</li>
                                        <li><FiUser />Ilinca Gheorghiu</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <div style={{textAlign:'justify'}} className="content">
                                        <p>Interactive Voice Response or IVR, is an automated telephone system. Interact with callers to gather information, redirecting calls to the most appropriate department. Can give answers in the form of pre-recorded voice messages, text messages, e-mail and / or other contact methods. It consists of telephony equipment, software applications and a support infrastructure. It has access to customer data and can archive the interaction.</p>
                                       
                                        <h3 className="title mt--40">How does it work?</h3>
                                
                                            </div><p className="mt--40"style={{textAlign:'justify'}}>Callers can choose one of the options presented by pressing a key, by the sound produced by this action. The system is called DTMF (Dual-Tone Multi-Frequency). Each of the keys produces a certain tone, a combination of two sounds made simultaneously, one high frequency and one low frequency.</p>
                                            
                                        <p className="mt--40"style={{textAlign:'justify'}}>The call is then directed to the agent via CTI (Computerized Telephone Integration). The IVR can process thousands of requests per second. Its configuration is done on branches and sub-branches. The branches selected by the customer and the number of seconds spent per person are recorded. Thus, reports can be made on the productivity of this technology.</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>In order to be able to implement this system, the following requirements must be met:</p>
                                        <ul className="list-style"style={{textAlign:'justify'}}>
                                                    <li><b>TCP/IP Network</b> - the network that ensures internet and intranet connectivity;</li>
                                                    <li><b>VXML telephony server</b> - a special server that serves as an interface between the phone and the internet;</li>
                                                    <li><b>Data Base</b> - real-time information that can be accessed by IVR;</li>
                                                    <li><b>Web server / application</b> - can be considered the “home” of the software of this technology. </li>
                                                    <li>speaks often on the phone, repeated exposure to a stimulus decreases in intensity.</li>
                                        </ul>
                                        <div className="thumbnail mt--40">
                                            <img style={{display:'block', marginLeft:'auto',marginRight:'auto',width:'80%'}} src="/assets/images/blog/IVR-struct.png" alt="BLog Images"/>
                                        </div>
                                        <h3 className="title mt--40"style={{textAlign:'justify'}}>The advantages of IVR</h3>
                                        <p className="mt--40"style={{textAlign:'justify'}}>IVR reduces transfer errors, can perform surveys and streamlines the duration of a call. It also has the option to prioritize certain calls based on importance and / or provide basic non-stop services, such as finding out the status of an order, the due date of an invoice, or the amount a person needs to pay. pay, by integrating with databases, eliminating in certain situations the need to talk to a representative (in the case of a complex IVR).</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>A company can use this technology to run personalized messages; also, in the event of a malfunction, the caller will be notified of their existence and the estimated time of settlement. This feature helps the number of calls in this situation. And, if someone calls outside of business hours, the company schedule will be played. Due to this filtering, the costs generated by resolving critical situations or even with the human resources needed to resolve these calls are reduced. It also has the ability to automatically initiate certain actions such as sending an SMS or e-mail or scheduling a return by a representative.</p>
                                        <p className="mt--40"style={{textAlign:'justify'}}>With the help of artificial intelligence and improvements in verbal communication processing, the IVR can end up having whole conversations with the caller. Advanced functions – such as voice recognition - where the caller can be authenticated only by voice timbre are currently in progress of being launched.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails;