const BlogContent = [
    {
        images: '01',
        title: 'Why are surveys important?',
        //category: '',
        link: 'why-are-surveys-important'
    },
    {
        images: '02',
        title: 'What is telephonophobia?',
       // category: 'Management',
        link: 'what-is-telephonophobia'
    },

    {
        images: '03',
        title: 'Interactive Voice Response',
        //category: 'Design',
        link: 'IVR'
    }
]

export default BlogContent;