import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import QuoteCustomercare from "./QuoteCustomercare";
import ScrollToTop from 'react-scroll-up';
import { FiCast , FiLayers , FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu} from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { Link } from "react-router-dom";
import Particles from 'react-particles-js';
import Scrollspy from 'react-scrollspy';
import BlogContent from "./blog/BlogContent";


class About extends Component{


  // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 500)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }



    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}


    
    render(){

        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }


        
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Scheduling & Reservations' />

                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                            <Scrollspy className="mainmenu" items={['home','about', 'solutions','team','testimonial','blog', 'contact']} currentClassName="is-current" offset={-200} > 
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#about">About</a></li>
                                    <li><a href="/#solutions">Solutions</a></li>
                                    <li><a href="/#team">Team</a></li>
                                    <li><a href="/#testimonial">Testimonial</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/blog`}>Blog</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/contact`}>Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}




                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area slider-creative-agency with-particles2  pt--120 pb--190 bg_image bg_image--34"  data-black-overlay="4">
                
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div  className="breadcrumb-inner pt--100">
                                <h2 className="title theme-gradient">Scheduling & Reservations</h2>
                                    <ul className="page-list">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}/solutions`}>Solutions</Link></li>
                                        <li className="breadcrumb-item"><a href={`${process.env.PUBLIC_URL}/solutions#inbound-support`}>Inbound Contact Management</a></li>
                                        <li className="breadcrumb-item active">Scheduling & Reservations</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                {/* End Breadcrump Area */}
                 
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <p>For businesses that rely on appointments & reservations for revenue, accurate calendar management is crucial.</p>
                                <p>Spending too much time scheduling and re-scheduling client appointments & reservations?</p>
                                <p>Need help with confirmations?</p>
                                <p> Our Customer Service Professionals can help your clients and guests 24/7 to find and reserve a time and day that meets their scheduled and needs. Whether you operate in the HoReCa or medical field, we will best ensure the satisfaction of your customers and your schedule will have never been so comprehensive.</p>
                                <a className="rn-btn btn-white" href="/contact">Get Started With Us</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/red-phone.png" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default About