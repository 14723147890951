import React, { Component } from "react";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget } from "react-icons/fi";

const ServiceList = [
    
]

class ServiceTwo extends Component{

    state = {
        show: false,
        
      }
    
        toggle = () => this.setState((currentState) => ({show: !currentState.show} ));


    render(){
        let title = 'Business Integration',
        description = 'Are you in need of other services than the traditional ones a Contact Center can provide? Here, at Bright Communication, we don`t limit ourselves to only inbound, outbound, and back office. We take it even further. In order to ensure you can have the all-around, brightest experience you can have, we offer a complex portfolio of services you can outsource to us, from professional consulting to recruitment and business continuity plan. We can help you build your business from the ground up!'
        return(
            <React.Fragment>
                <div className="row">
                    
                    <div className="col-lg-8 col-12 mt_md--50">
                    <div className="thumbnail service service__style--1" > <a href="/solutions/business-integration"><img src={`/assets/images/bi3.png`}/></a> </div>

                    </div>

                    <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 style={{fontSize:'30px'}} className="title">{title}</h2>
                            <p style={{textAlign:'justify'}}>{description}</p>
                           <p>  <a href="/solutions/business-integration"><img src={`/assets/images/icons/arrow-left.png`} /></a></p>
                            
                        </div>
                        
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
