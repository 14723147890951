import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import FooterTwo from "../component/footer/FooterTwo";
import Scrollspy from 'react-scrollspy';
import BlogContent from "./blog/BlogContent";
import { FiChevronUp , FiX , FiMenu} from "react-icons/fi";
import { Link, animateScroll as scroll } from "react-scroll";

class ServiceDetails extends Component{

          // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 500)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }


    openModal () {
        this.setState({isOpen: true})
    }

    constructor(props) {
         super(props)
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Telemarketing' />
                {/* End Pagehelmet  */}

                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                            <Scrollspy className="mainmenu" items={['home','about', 'solutions','blog', 'contact']} currentClassName="is-current" offset={-200} > 
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#about">About</a></li>
                                    <li><a href="/#solutions">Solutions</a></li>
                                    
                                    <li><a href={`${process.env.PUBLIC_URL}/blog`}>Blog</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/contact`}>Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Start Breadcrump Area */}
                <div  style={{height:'100vh'}}>
                <div className="rn-page-title-area slider-creative-agency with-particles2 " >
                <video style={{ objectFit:'cover' ,position: 'absolute', width:'100vw', height:'100vh'}} className="video-mobile video-desktop"  autoPlay loop muted>
                <source src={`/assets/images/telemark - Copy.mp4`} type="video/mp4" />
                </video>
                
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div  className="breadcrumb-inner">
                                <h2 className="title theme-gradient">Telemarketing</h2>
                                    <ul className="page-list">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}/solutions`}>Solutions</Link></li>
                                        <li className="breadcrumb-item"><a href={`${process.env.PUBLIC_URL}/solutions#customer-acquisition`}>Customer acquisition</a></li>
                                        <li className="breadcrumb-item active">Telemarketing</li>
                                    </ul>
                                    <h2 className="title ">
                                    <ul style={{paddingTop: '10%'}} className="page-list">
                                    <Scrollspy items={['scroll']}>
                                    <Link to="scroll" smooth={true} duration={500}  ><li style={{paddingLeft: "0px" , paddingRight: "0px", cursor: 'pointer' }} className="mouse"></li></Link>
                                    </Scrollspy>
                                    </ul>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                {/* End Breadcrump Area */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us pt--100 bg_color--1" id="scroll">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h3 style={{color:'red', paddingBottom:'20px' , textAlign:'justify'}}>The magic ‘mind reading’ phrase is: “If that was me, what would I want?”</h3>
                                <p style={{textAlign:'justify'}} >Telemarketing may entail a variety of activities, such as surveying, appointment-setting, database maintenance and cleaning, and providing a call to action. </p>
                                <p style={{textAlign:'justify'}}>It is used by businesses, non-profit charities, political groups and candidates, and other kinds of organizations. However, no matter the channel, product or company, it needs an edge to be successful. Your strategy must be engaging, fun and intelligent, otherwise it will bore your customers. Ask yourself, “If that was me, what would I want?” </p>
                                <a className="rn-btn btn-white" href="/contact">Get Started With Us</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/telemarketing.jpg" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;