import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiMenu, FiX } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "./blog/BlogContent";
import Scrollspy from 'react-scrollspy';

class BlogDetails extends Component{
         // fake authentication Promise
  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }
    constructor(props)  {
        super(props)
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })

    }
    openModal () {
        this.setState({isOpen: true})
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}


    render(){

        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }


        return(
            <React.Fragment>
                <PageHelmet pageTitle='What is telephonophobia?' />
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','about', 'solutions','blog', 'contact']} currentClassName="is-current" offset={-200} > 
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#about">About</a></li>
                                    <li><a href="/#solutions">Solutions</a></li>
                                    
                                    <li><a href={`${process.env.PUBLIC_URL}/blog`}>Blog</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" data-black-overlay="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Terms and conditions <br /> </h2>
                                    
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner"style={{textAlign:'justify'}}>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>GENERAL INFORMATION</h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b>Bright Communication SRL</b> with registered office in Bucharest, 24th Delea Veche Street, A Building, 4th floor, District 2, enrolled with the Trade Register Office under number J40/5043/2019 having the TAX Registration code RO 40970260 has developed this Privacy policy to inform you of the conditions under which we collect, process, use and protect your personal data. This section, hereinafter referred to as the “Privacy Policy”, describes your rights regarding the protection of personal data, the way in which they are exercised and the communication channels through which you can contact us to report any deviation from the Policy. The information presented in this section is very important. We recommend that you read them carefully before using Our Service. </p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>1. PURPOSE</h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>The purpose of this Privacy Policy is to explain what data we process, why we process it and how, and your rights under the GDPR.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Regarding the personal data we collect, Bright Communication SRL is a “data operator”, ie the entity that establishes the purposes for which and the means by which personal data are processed.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>By visiting the site or by interacting with us by any means and / or through any communication channel (e-mail, telephone, social networks, etc.), you agree to this Privacy Policy and consent to the use of your data for advertising or marketing purposes, as described below in the dedicated sections.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>If you do not agree with those described in this Privacy Policy, please do not use our services or products.</p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>2. TARGET PERSON</h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>According to (EU) 679/2016 (“GDPR”) standard, when you access our site you have the status of “data subject”, i.e., an identified or identifiable person.</p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>3. GDPR COMPLIANCE </h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Bright Communication is committed to complying with personal data protection legislation.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>The principles established by GDPR:</p>
                                    <ul className="list-style">
                                                    <li><b>Legality and transparency.</b> Bright Communication undertakes to process your data in accordance with the provisions of the Regulation. We are transparent with the way your data is processed and we will inform you of any changes to our personal data protection policy.</li>
                                                    <li><b>The right to be informed.</b> We offer you the opportunity to analyze, modify or delete the personal data you have provided to us. We also ensure that your data is accurate, complete and up to date. If you find that they do not meet one of these characteristics, we are at your disposal to make the necessary changes.</li>
                                                    <li><b>Purpose of processing.</b> Personal data will be used exclusively for GDPR compatible purposes</li>
                                                    <li><b>Data security.</b> The processing of personal data is carried out in safe conditions, which include protection against unauthorized or illegal processing and against accidental loss, destruction or damage, by taking appropriate technical or organizational measures. Thus, at the level of the organization there are security and encryption systems that ensure a reasonable level of protection. To this end, we take all necessary precautions, taking into account the nature of personal data and the risks associated with the processing of data, to maintain data security and, in particular, to prevent distortion, damage or unauthorized access by third parties (physical protection of the location, authentication procedures with personal access, secured by confidential identifiers and passwords, a login log, encryption of certain data, etc.). However, please note that any web page may have malfunctions that exceed the operator's forecasting ability.</li>
                                    </ul>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>4. TARGETED PERSON’S RIGHTS </h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Please note that you have the right to request the following from Bright Communication at any time:</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>1. Access to your personal information.</u></b> You can send us a request to ask us what personal data is used and to request access to your personal data. You also have the right to know the purposes of the processing, the categories of personal data we hold, the parties or categories of parties with whom your personal data is shared, the data retention period, and the source of the data.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>2. The right to request a copy of the personal information we hold.</u></b> You can contact us at contact@brightcommunication.ro</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>3. The right to request an update of incorrectly entered data. </u></b>You can contact us at any time to let us know when your personal information is inaccurate and out of date. You can also ask Bright Communication to correct or remove information that is considered inaccurate or outdated.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>4. The right to request the deletion of personal information. </u></b>You may ask us to stop processing or even delete your personal information. If personal information is necessary to fulfill a contractual obligation of Bright Communication, our Company may be unable to fulfill this obligation. Also, if your personal information is necessary for our Company to comply with certain legal obligations (eg tax law), your request cannot be granted.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>5. Right to restrict Bright Communication and / or third-party access to your data on certain processes or in whole.</u></b> If you have challenged the accuracy of the data or we no longer need the personal data for the purpose of processing, but it is requested by you to establish, exercise or defend legal claims or you have objected to the processing based on the reasons that Bright Communication considers legitimate, you have the right to request a restriction on the processing of personal information.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>6. The right to object to the way we handle your data. </u></b>You have the right to object to the processing of personal data based on a legal basis that Bright Communication considers legitimate. In addition, you have the right to object at any time to the processing of personal data for direct marketing purposes, including profiling insofar as it relates to direct marketing.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>7. The right to request the transfer of data to another data controller (right to portability).</u></b></p>
                                    <p className="mt--40"style={{textAlign:'justify'}}><b><u>8. The right to address the court to defend your rights and interests or to lodge a complaint with a Supervisory Authority.</u></b>If you are not satisfied with the way we collect or use your personal information, you have the right to submit a formal complaint to the National Authority for the Supervision of Personal Data Processing, accessing the page http://www.dataprotection.ro. </p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>5. WHAT INFORMATION DOES BRIGHT COMMUNICATION COLLECT ABOUT YOU? </h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>The usual categories of data we collect from you are: name, address, date of birth, gender, preferred language, phone number, email address, or other payment information you provide when you want to enter contact with us.</p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>6. WHERE AND FOR WHAT PERIOD ARE THESE DATA STORED? </h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>We keep the personal data we collect in secure environments. Personal information is protected from unauthorized access, disclosure, use, modification or destruction by any organization or individual.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>The processed data is stored in Bright Communication's premises and IT systems, but sometimes it is possible to store the data on the servers of our trusted service providers.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Personal data will be stored exclusively for the period necessary to fulfill the purpose, but not more than 5 years from the termination of the contract or from the last interaction with us. In certain situations, the law requires a longer storage period, in which case we will be obliged to act as such.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Subsequently, the data is deleted, destroyed or transformed into anonymous data, in accordance with the legal provisions and our Personal Data Retention Policies.</p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>7. WHY WE COLLECT PERSONAL DATA? </h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>In general, Bright Communication collects personal data to improve contact with our customers and the services we provide to them. We also collect personal data for the following purposes:</p>
                                    <ul className="list-style">
                                                    <li>to comply with legal provisions;</li>
                                                    <li>to respond to and respond to your requests, questions and comments;</li>
                                                    <li>in case of providing customer support services;</li>
                                                    <li>to diagnose server problems, administer the site, and verify that it is working properly.</li>
                                                    <li>to defend ourselves against cyber-attacks;</li>
                                                    <li>for analytical and research purposes;</li>
                                                    <li>legal protection of our interests.</li>
                                    </ul>
                                    <p className="mt--40"style={{textAlign:'justify'}}>The data is being processed on the basis of your consent, our legitimate interest or compliance with certain legal provisions.</p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>8. COOKIES </h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>To maintain our site and ensure its proper functionality, Bright Communication uses technology generally known as "cookies". Cookies are small files that we send to your computer and we can access them later. They can be temporary or permanent and use, for example, JavaScript or Flash technology. Thanks to the cookies on our website, you can search without difficulty and we can show you the results relevant to you. The cookies on the Bright Communication site show us what interests you and other visitors, which helps us to we improve its use for everyone.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>The most common cookies are the following:</p>
                                    <ul className="list-style">
                                                    <li>Strictly necessary. These are necessary to ensure that the website works efficiently from a technical perspective.</li>
                                                    <li>Functional cookies. They help to improve the experience, remembering your choices.</li>
                                                    <li>Performance cookies. These cookies are used to measure how customers navigate our website and find out how well we perform.</li>
                                                    <li>Redirection cookies. These cookies are used to help Bright Communication place advertisements on other web pages that the customer visits, or to avoid placing advertisements for a customer who already knows Bright Communication. Check with your local digital / marketing team in detail how these cookies are used.</li>
                                                    <li>Social media plug-ins. These cookies are used to connect our customers to social media channels. After that, the information provided by the cookie can be used in different ways.</li>
                                    </ul>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Cookies stored on your computer can be accessed when you visit our website or open an email that we send you. If you want to delete cookies that are already on your computer, see your browser's instructions by clicking "Help" in the same browser menu.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Bright Communication only processes cookies with your consent. To withdraw your consent or agree to the use of cookies, make the appropriate settings in your browser. However, some of the features of our site cannot work without cookies. </p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>By requesting this agreement, we will present the purpose for which this type of information about you will be processed and we will inform you about your rights.</p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>9. OTHER WEBSITES</h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Bright Communication websites may contain links to and from third party websites. This Policy applies only to this website, so when you transfer to other websites you must read their privacy policies.</p>
                                    <h3 className="title mt--40"style={{textAlign:'justify'}}>10. MARKETING</h3>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Bright Communication would like to send you information about our products and services. However, we can only send such marketing materials if you opt for them. Please note that even if you have agreed to receive our marketing messages, you can easily choose not to receive them at any time.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>Bright Communication reserves the right to change this Privacy Policy at any time by posting it on the site and / or notifying you by e-mail.</p>
                                    <p className="mt--40"style={{textAlign:'justify'}}>If you have any questions or concerns about how our data is processed by us or if you wish to exercise any of the above rights in relation to the data we hold, please contact us by e-mail: contact@brightcommunication.ro</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails;