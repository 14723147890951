import React, { Component } from "react";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget } from "react-icons/fi";
import {  FaMailBulk  } from "react-icons/fa";
import {  SiMinutemailer  } from "react-icons/si";


const ServiceList = [
    {
        id:'live-chat',
        icon: <FiCast />,
        title: 'Live Chat',
        description: 'Good customer service costs less than bad customer service.'
    },
    {
        id:'email-support',
        icon: <SiMinutemailer />,
        title: 'Email Support',
        description: 'Email has the abilty many channels don’t: personal touches - at a scale.'
    },
    {
        id:'email-marketing',
        icon: <FaMailBulk />,
        title: 'Email Marketing',
        description: 'Customers don’t sign up for email – they sign up for your brand.'
    },
    {
        id:'social-media-communication',
        icon: <FiLayers />,
        title: 'Social Media Communication',
        description: 'Focus on how to be social, not on how to do social.'
    }
]

class ServiceTwo extends Component{

    state = {
        show: false,
        
      }
    
        toggle = () => this.setState((currentState) => ({show: !currentState.show} ));


    render(){
        let title = 'Online Support',
        description = 'When the going gets tough, the tough go online. Even before, if you did not have an online presence, you did not exist. Just imagine how vital it is now, in our evershifting status quo. Don’t know where to start? Our team of experienced specialists that can create a state of the art content and help you engage your audience at your maximum potential. Experience the difference that outsourcing your online presence tasks can do for your company.'
        return(
            <React.Fragment>
                <div className="row">
                    
                    <div className="col-lg-8 col-12 mt_md--50">
                    {this.state.show &&    <div className="row service-one-wrapper ">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`/solutions/${val.id}`}>
                                        <div className="service service__style--2 ">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>}
                        {!this.state.show && <div onClick={this.toggle} className="thumbnail service service__style--1" > <img src={`/assets/images/online.png`}/> </div>}

                    </div>

                    <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 style={{fontSize:'30px'}} className="title">{title}</h2>
                            <p style={{textAlign:'justify'}}>{description}</p>
                            <p onClick={this.toggle}> {this.state.show ? <img src={`/assets/images/icons/arrow-right.png`} /> :  <img src={`/assets/images/icons/arrow-left.png`} />}</p>
                            
                        </div>
                        
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
