import React, { Component } from "react";
import { FiCast ,FiPieChart , FiZap , FiRefreshCw, FiSettings, FiLayers , FiPhoneOutgoing, FiUsers , FiMonitor , FiPercent, FiUser , FiChevronUp , FiX , FiMenu, FiTrendingUp, FiCreditCard, FiTarget } from "react-icons/fi";

const ServiceList = [
    {
        id:'customer-care',
        icon: <FiUsers />,
        title: '[RO]Customer Care',
        description: 'Your customers don’t care how much you know until they know how much you care.'
    },
    {
        id:'tehnical-support-help-desk',
        icon: <FiUsers />,
        title: '[RO]Tehnical Support & Help Desk',
        description: 'Further then "Did you try to turn it off and on again?"'
    },
    {
        id:'order-management',
        icon: <FiUsers />,
        title: '[RO]Complaints & Order Management',
        description: 'The first rule of management is delegation.'
    },
    {
        id:'billing-finances',
        icon: <FiUsers />,
        title: '[RO]Billing & Finances',
        description: 'An investment in knowledge pays the best interest.'
    },
]

class ServiceTwo extends Component{

    state = {
        show: false,
        
      }
    
        toggle = () => this.setState((currentState) => ({show: !currentState.show} ));


    render(){
        let title = '[RO]Inbound Contact Management',
        description = '[RO]For every question, there must be an answer. Providing solutions to each customer query will take sustained, focused, hard work — and that’s okay. We are well equipped for that. No matter your industry, our representatives are trained to truly understand and speak to your brand. We make sure that when a costumer calls, they can get the most accurate response as soon as possible.';
        return(
            <React.Fragment>
                <div className="row">
                    <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 style={{fontSize:'30px'}} className="title">{title}</h2>
                            <p style={{textAlign: 'justify'}}>{description}</p>
                            <p onClick={this.toggle}> {this.state.show ? <img src={`/assets/images/icons/arrow-left.png`} /> :  <img src={`/assets/images/icons/arrow-right.png`} />}</p>
                            
                        </div>
                        
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                    {this.state.show &&    <div className="row service-one-wrapper ">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`/solutions/${val.id}`}>
                                        <div className="service service__style--2 ">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p style={{textAlign: 'justify'}}>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>}
                        {!this.state.show && <div onClick={this.toggle} className="thumbnail service service__style--1" > <img src={`/assets/images/inbound3.png`}/> </div>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
