import React, { Component } from "react";
import { FiMonitor, FiPhoneIncoming, FiPhoneOutgoing, FiCodesandbox, FiCloud, FiZap } from "react-icons/fi";

const ServiceList = [
    {
        id:'inbound-services',
        icon: <FiPhoneIncoming />,
        title: 'Inbound Contact Management ',
        description: 'For every question, there must be an answer.'
    },
    {
        id:'customer-acquisition',
        icon: <FiPhoneOutgoing />,
        title: 'Customer acquisition',
        description: 'Work smarter, not harder ! Work Bright!' 
        
    },
    {
        id:'back-office',
        icon: <FiMonitor />,
        title: 'Back Office',
        description: 'Sometimes, it’s not only about the big picture...'
    },
    ,
    {
        id:'online-support',
        icon: <FiCloud />,
        title: 'Online Support',
        description: 'When the going gets tough, the tough go online.'
    },
    /* {
        id:'bpo',
        icon: <FiCodesandbox />,
        title: 'Business Process Outsourcing',
        description: 'We don`t limit ourselves to only inbound, outbound, and back office. We take it even further. Find out more details here'
    },
    {
        id:'technical-support',
        icon: <FiSettings />,
        title: 'Technical support',
        description: 'WWe deliver industry-leading technology designed to pair perfectly with our world-class answering services and call center solutions. '
    }, */
    {
        id:'bi',
        icon: <FiCodesandbox />,
        title: 'Business Integration',
        description: 'You didn’t come this far, just to get this far.'
    }

]

class ServiceTwo extends Component{
    render(){
        let title = 'Solutions',
        description = 'Just showing up is not enough. We are here today and each day, with solutions, tailored for you.';
        return(
            <React.Fragment>
                <div className="row">
                    <div  className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            
                            <h2 className="title">{title}</h2>
                            <p style={{textAlign:'justify'}}>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/solutions"><span className="text">Request Custom Solution</span></a>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper ">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`/solutions#${val.id}`}>
                                        <div className="service service__style--2 ">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                    
                                </div>
                            ))}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 pt--25" >
                            <a href={`/solutions#bpo`}>
                            <div className="service service__style--3 ">
                                            <div className="icon">
                                            <FiZap/>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Business Process Outsourcing</h3>
                                                <p>Do what you do best. Outsource the rest.</p>
                                            </div>
                                        </div>
                                    </a>


                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
